
























































import { computed, defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  components: {
    OInventoryUsers: () => import('@/components/organisms/o-inventory-users.vue'),
    OInventoryVehicles: () => import('@/components/organisms/o-inventory-vehicles.vue'),
    OInventoryItems: () => import('@/components/organisms/o-inventory-items.vue'),
    OInventoryAction: () => import('@/components/organisms/o-inventory-action.vue'),
    OInventoryCreate: () => import('@/components/organisms/o-inventory-create.vue')
  },
  setup(_, { root }) {
    const refs = {
      users: ref(),
      vehicles: ref(),
      accessories: ref(),
    }

    const state = reactive({
      itemKey: new Date().getTime(),
      tab: computed({
        get: () => parseInt(root.$route.query['inventory.tab'] as string),
        set: (value: number) => root.$router.replace({ query: { ...root.$route.query, 'inventory.tab': value.toString() } }).catch(() => [])
      }),
      actionDialog: {
        isOpen: false,
        defaults: {}
      },
      creationDialog: { isOpen: false }
    })

    const action = (options: any) => {
      state.actionDialog.defaults = options
      state.actionDialog.isOpen = true
    }

    const refresh = () => {
      Object.values(refs).forEach((item) => {
        if (item.value && 'refresh' in item.value) item.value.refresh()
      })
    }

    return { state, action, refresh, ...refs }
  }
})
